var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c("c-table", {
                ref: "table2",
                attrs: {
                  title: "검진항목 목록",
                  tableId: "table2",
                  columns: _vm.grid2.columns,
                  data: _vm.grid2.data,
                  gridHeight: _vm.grid2.height,
                  columnSetting: false,
                  filtering: false,
                  hideBottom: true,
                  usePaging: false,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }