<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table2"
            title="검진항목 목록"
            tableId="table2"
            :columns="grid2.columns"
            :data="grid2.data"
            :gridHeight="grid2.height"
            :columnSetting="false"
            :filtering="false"
            :hideBottom="true"
            :usePaging="false"
          >
            <!-- 버튼 영역 -->
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'check-up-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaCheckupResultId: '',
      }),
    },
  },
  data() {
    return {
      editable: false,
      // 검진항목
      grid2: {
        columns: [
          {
            name: 'examineName1',
            field: 'examineName1',
            label: '검진항목',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'examineResult1',
            field: 'examineResult1',
            label: '검진결과',
            align: 'center',
            setHeader: true,
            style: 'width: 130px',
            sortable: false,
          },
          {
            name: 'examineName2',
            field: 'examineName2',
            label: '검진항목',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'examineResult2',
            field: 'examineResult2',
            label: '검진결과',
            align: 'center',
            setHeader: true,
            style: 'width: 130px',
            sortable: false,
          },
        ],
        data: [],
        height: '700px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    // contentHeight() {
    //   let height = (this.contentHeight - this.grid1.height);
    //   if (height < 400) {
    //     height = 500;
    //   }
    //   this.grid2.height = height + 'px'
    // }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.hea.checkup.result.get.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.heaCheckupResultId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.heaCheckupResultId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid2.data = _result.data.examineList;
        },);
      } 
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>